<template>
  <div class="doc-td">
    <slot />
  </div>
</template>

<script>
export default {
  name: "DocTd"
}
</script>
