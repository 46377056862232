<template>
  <div class="doc-th">
    <slot />
  </div>
</template>

<script>
export default {
  name: "DocTh"
}
</script>
